
/*
  Footer component for the site.
*/
export default {
  name: 'CaFooter',
  mixins: [],
  props: {},
  data: () => ({}),
  computed: {
    uspLogoPlacement() {
      return this.$store.getters.viewport === 'phone' ? 'top' : 'left';
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
