import { render, staticRenderFns } from "./CaFooter.vue?vue&type=template&id=bab5f8a0"
import script from "./CaFooter.vue?vue&type=script&lang=js"
export * from "./CaFooter.vue?vue&type=script&lang=js"
import style0 from "./CaFooter.vue?vue&type=style&index=0&id=bab5f8a0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIconAndText: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaIconAndText/CaIconAndText.vue').default,CaContainer: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaContainer/CaContainer.vue').default,CaNewsletter: require('/app/node_modules/@geins/ralph-ui/components/molecules/CaNewsletter/CaNewsletter.vue').default,CaLogo: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaLogo/CaLogo.vue').default,CaBookheroDescription: require('/app/components/molecules/CaBookheroDescription/CaBookheroDescription.vue').default,CaFooterNavigation: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaFooterNavigation/CaFooterNavigation.vue').default,CaSvgAsset: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaSvgAsset/CaSvgAsset.vue').default})
