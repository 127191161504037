
/*
  CaBookheroDescription Component
  This component generates a description with multiple links for different book categories.
*/

export default {
  name: 'CaBookheroDescription',
  data() {
    return {
      linksByLocale: {
        sv: [
          { url: '/l/bocker/facklitteratur', text: this.$t('FICTION') },
          { url: '/l/bocker/mat-dryck', text: this.$t('FOOD_DRINK') },
          { url: '/l/bocker/barn-ungdomsbocker', text: this.$t('CHILDREN_YOUTH_BOOKS') },
          { url: '/l/bocker/hobby-fritidsbocker', text: this.$t('HOBBY_LEISURE') },
          { url: '/l/bocker/biografier', text: this.$t('BIOGRAPHIES') },
          { url: '/l/bocker/skonlitteratur', text: this.$t('LITERATURE') },
        ],
        da: [
          { url: '/l/bocker/facklitteratur', text: this.$t('FICTION') },
          { url: '/l/bocker/mat-dryck', text: this.$t('FOOD_DRINK') },
          { url: '/l/bocker/barn-ungdomsbocker', text: this.$t('CHILDREN_YOUTH_BOOKS') },
          { url: '/l/bocker/hobby-fritidsbocker', text: this.$t('HOBBY_LEISURE') },
          { url: '/l/bocker/biografier', text: this.$t('BIOGRAPHIES') },
          { url: '/l/bocker/skonlitteratur', text: this.$t('LITERATURE') },
        ],
        en: [
          { url: '/l/books/fiction', text: this.$t('FICTION') },
          { url: '/l/books/food-drink', text: this.$t('FOOD_DRINK') },
          { url: '/l/books/children-ya', text: this.$t('CHILDREN_YOUTH_BOOKS') },
          { url: '/l/books/hobby-leisure', text: this.$t('HOBBY_LEISURE') },
          { url: '/l/books/biographies', text: this.$t('BIOGRAPHIES') },
          { url: '/l/books/literature', text: this.$t('LITERATURE') },
        ],
      },
    };
  },
  computed: {
    links() {
      return this.linksByLocale[this.$i18n.locale] || [];
    },
  },
};
